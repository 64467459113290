import React from 'react';
import { Outlet } from 'react-router-dom';
import './onboarding.css';

function OnboardingLayout() {
  return (
    <main className="container-fluid">
      <div className="row">
        <Outlet />
      </div>
    </main>
  );
}

export default OnboardingLayout;
